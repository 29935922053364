import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Socket} from 'ngx-socket-io';
import {CommonResponse} from '@core/models/common/common-response';
import {SocketNameSpace} from './socket-namespace';
import {environment} from '@env/environment';
import {AuthService} from '../auth/auth.service';
import {PartyJoinType} from '@core/enums/party/party-join-type.enum';
import {PartyMessageSocket} from '@core/models/chat/socket/party-message-socket';
import {Room} from '@core/models/candidate/room';

@Injectable({
    providedIn: 'root',
})
export class ChatSocketService {
    private readonly chatAppUrl: string;

    chatSocket!: SocketNameSpace;
    socketId!: string;
    private token = localStorage.getItem('token');
    isConnected$: BehaviorSubject<boolean>;
    isDisconnected$: BehaviorSubject<boolean>;
    onlineRooms: Room[];
    activeRoomIds: number[];
    bannedRooms: number[];
    /* initialFeatureData$ = new BehaviorSubject<PartyDataSocket>({
        bestSupporters: [],
        partyFeature: [],
    });*/

    constructor(
        private http: HttpClient,
        private socket: Socket,
        private authService: AuthService,
    ) {
        this.onlineRooms = [];
        this.bannedRooms = [];
        this.activeRoomIds = [];
        this.isConnected$ = new BehaviorSubject<boolean>(false);
        this.isDisconnected$ = new BehaviorSubject<boolean>(false);

        this.chatAppUrl = environment.apiUrl + 'chat/';
    }

    sendMessageViaService(userId: number, message: string): Observable<unknown> {
        const reqBody = {
            message: message,
            receiverId: userId,
        };

        let reqHeaders = new HttpHeaders();
        reqHeaders = reqHeaders.append('Authorization', `Bearer ${this.token}`);
        const options = {headers: reqHeaders};
        return this.http.post(this.chatAppUrl, reqBody, options);
    }

    updateUrlSocket() {}

    connectSocket() {
        this.chatSocket = new SocketNameSpace({
            url: environment.socketUrl,
            options: {
                query: {token: this.authService.appSession$.getValue()!.socketToken},
                transports: ['websocket'],
            },
        });
        this.chatSocket.ioSocket.on('connect', () => {
            this.socketId = this.chatSocket.ioSocket.id;
            this.isConnected$.next(true);
            this.isDisconnected$.next(false);
        });

        this.chatSocket.ioSocket.on('disconnect', () => {
            this.isConnected$.next(false);
            this.isDisconnected$.next(true);
        });
    }

    disconnectSocket() {
        this.chatSocket.removeAllListeners();
        this.chatSocket.disconnect();
    }

    fetchMessages() {
        this.chatSocket.emit('message', []);
    }

    onFetchMessages() {
        return this.chatSocket.fromEvent('message');
    }

    sendMessage(msg: PartyMessageSocket) {
        this.chatSocket.emit('message', msg);
    }

    sendMessageAck(msg: PartyMessageSocket) {
        this.chatSocket.emit('messageAck', msg);
    }

    joinParty(roomId: number, ticketType: PartyJoinType) {
        this.chatSocket.emit('joinParty', {roomId: roomId, ticketType: ticketType});
    }

    onJoinParty() {
        return this.chatSocket.fromEvent<string>('joinParty');
    }

    onJoinResponse() {
        return this.chatSocket.fromEvent<string>('joinResponse');
    }

    onLivePartyList() {
        return this.chatSocket.fromEvent<string>('livePartyList');
    }

    onfree2ProList() {
        return this.chatSocket.fromEvent<string>('free2ProList');
    }

    onFinishParty() {
        return this.chatSocket.fromEvent<string>('finish');
    }

    onStreamToolChange() {
        return this.chatSocket.fromEvent<string>('streamToolsChange');
    }

    partyData() {
        /*return this.chatSocket.emit('partyData', {id: 0},  (data: PartyDataSocket)=> {
            console.log(data);
        });*/
    }
    partyMessage(messageData: unknown) {
        this.chatSocket.emit('partyMessage', messageData);
    }

    onJoinPartyMessage() {
        return this.chatSocket.fromEvent<string>('partyMessage');
    }

    onPartyFeatureV2() {
        return this.chatSocket.fromEvent<string>('partyFeatureV2');
    }

    onPartyFeature() {
        return this.chatSocket.fromEvent<string>('partyFeature');
    }
    /*
    cmd (string)
    value (integer)
    values [](integer)
    */
    onPartyInfo() {
        return this.chatSocket.fromEvent<string>('partyInfo');
    }

    onApiCommands() {
        return this.chatSocket.fromEvent<string>('apiCommands');
    }

    //qrLogin
    onQrLogin() {
        return this.chatSocket.fromEvent<string>('qrLogin');
    }

    onRoomNotice() {
        return this.chatSocket.fromEvent<string>('roomNotice');
    }

    getParticipantInfo(pId: number) {
        this.chatSocket.emit('getParticipantInfo', {pId: pId});
    }

    onGetParticipantInfo() {
        return this.chatSocket.fromEvent<string>('getParticipantInfo');
    }

    spinPartyWheel(wheelId: string) {
        return this.chatSocket.emit('partyWheelSpin', {wheelId});
    }

    markAsSeen(roomId: string): Observable<unknown> {
        const formData = new FormData();
        formData.append('roomId', roomId);
        let reqHeaders = new HttpHeaders();
        reqHeaders = reqHeaders.append('Authorization', `Bearer ${this.token}`);
        const options = {headers: reqHeaders};
        return this.http.post(this.chatAppUrl + 'messages/markAsSeen', formData, options);
    }

    public uploadFile(file: File): Observable<CommonResponse> {
        const formData = new FormData();
        formData.append('media', file);

        let reqHeaders = new HttpHeaders();
        reqHeaders = reqHeaders.append('Authorization', `Bearer ${this.token}`);
        const options = {headers: reqHeaders};
        return this.http.post<CommonResponse>(this.chatAppUrl + 'media', formData, options);
    }
}
